.dx-widget {
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  //keydown enter tab remove background
  &:not(.noBGSelect) {
    .cell-selected:not(.dx-editor-cell) {
      //color: #fafafa !important;
      background-color: #c7e2ff !important;
    }
  }
}
.dx-datagrid {
  color: $dataGridCellFontColor;
  //Headers....
  .dx-datagrid-headers {
    color: $dataGridCellFontColor;
    font-weight: bold;
    background-color: #fafbfc;
    border-radius: 4px 4px 0 0;
    .dx-datagrid-content {
      .dx-datagrid-table {
        tr {
          td {
            //border: none;
            font-size: .75rem;
            font-weight: bold;
            padding-top: 4px;
            padding-bottom: 4px;
            text-transform: uppercase;
            height: 40px;
          }
          &.dx-header-row {
            td {
              font-weight: bold;
              background-color: $dataGridHeaderBgColor !important;
              overflow-y: visible;
              &:not([colspan]) {
                border-bottom: none;
              }
              &.iconGuids {
                display: inherit;
                border: none !important;
                background-color: transparent !important;
                position: absolute;
                left: 3px;
                z-index: 99999999;
              }
              &.text-left {
                text-align: left !important;
              }
              &.text-right {
                text-align: right !important;
              }
              &:first-child {
                border-top-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
              }
            }
          }
          &.dx-datagrid-filter-row {
            td {
              background-color: #FAFBFC;
              &.dx-editor-cell {
                padding: 4px 8px !important;
                .dx-state-focused {
                  .dx-texteditor-container {
                    outline: #0095FF;
                    border: 1px solid #0095FF !important;
                  }
                }
              }
              .dx-texteditor-container {
                outline: #7F828E;
                border: 1px solid #AAACB4 !important;
                box-sizing: border-box;
                border-radius: 4px;
                padding-left: 8px;
                padding-right: 8px;
                background-color: #FFFFFF;
                text-transform: initial;
                .dx-placeholder {
                  color: #AAACB4;
                }
              }
            }
          }
        }
      }
    }
    //.dx-datagrid-rowsview {
    //  .dx-select-checkboxes-hidden {
    //    .dx-select-checkbox {
    //      display: block;
    //    }
    //  }
    //}
  }

  //Rowsview
  .dx-datagrid-rowsview {
    border-top: none;
    .dx-datagrid-content {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      &:not(.dx-datagrid-content-fixed) {
        .dx-datagrid-table {
          tr {
            &.dx-selection.dx-row, &.dx-selection.dx-row:hover {
              > td {
                background-color: $dataGridSelectedBgColor;
                color: $dataGridSelectedFontColor;
                a {
                  color: $dataGridSelectedFontColor !important;
                }
              }
            }
          }
        }
      }
      .dx-datagrid-table {
        //
        tr {
          border-top: none;
          border-bottom: none;
          &.dx-state-hover:not(.dx-header-row) {
            .grid-action-hover {
              display: table-cell !important;
            }
          }
          &.dx-header-row {
            background-color: #E4E9F2;
            .grid-action-hover, .grid-action-hover.hidden {
              background-color: transparent !important;
            }
            // transition: all .5s;
          }
          //
          td {
            &.grid-action-hover, &.grid-action-hover.hidden {
              display: none;
              // transition: all .5s;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
            & .grid-action-opacity {
              opacity: 0;
            }
            &.dx-focused {
              outline: 1px solid #111D5E !important;
            }
          }
          .dx-editor-cell:not(.dx-datagrid-readonly):not(.dx-editor-inline-block) {
            outline: 1px solid #111D5E;
            background-color: #ffffff !important;
          }
        }
      }
    }
  }

  //General..
  .dx-datagrid-content {
    .dx-datagrid-table {
      &.dx-pointer-events-none.dx-datagrid-table-fixed {
        .dx-state-hover {
          background-color: $dataGridRowHoverColor;
        }
        .dx-selection {
          background-color: $dataGridSelectedBgColor;
        }
      }
      //
      tr {
        &.dx-row-alt > td {
          background-color: $dataGridBgEvenColor;
          border-top: none;
          border-bottom: none;
        }
        &.dx-row-lines > td {
          border-bottom: 1px solid #E4E9F2;
          &.grid-action-hover {
            border-top: 1px solid #E4E9F2;
            border-bottom: 1px solid #E4E9F2;
          }
        }
        //
        > td {
          .display-action-button-w29f2005{
            visibility: hidden;
          }
        }
        td {
          min-height: 40px;
          font-size: 1rem;
          vertical-align: middle !important;
          padding-top: 4px;
          padding-bottom: 4px;
          height: 40px;
          &.dx-editor-cell .dx-texteditor .dx-texteditor-input {
            font-size: $fontSize;
            font-weight: 500;
            height: 38px;
            line-height: 38px;
          }
          &.dx-command-edit.dx-command-edit-with-icons {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  //Others
  .dx-datagrid-cell-updated-animation {
    cursor: text !important;
  }
}

//.grid_luc, .grid_luc_12{
//  .dx-header-row td {
//    background-color: $primaryColor !important;
//  }
//  .dx-datagrid-headers {
//    td{
//      color: white;
//      padding: 0 10px !important;
//      font-weight: 400 !important;
//      height: 30px;
//    }
//    .dx-datagrid-text-content{
//      white-space: pre-line;
//      font-size: 14px;
//      line-height: 18px;
//    }
//  }
//  .dx-datagrid-rowsview{
//    color: #737373;
//    td{
//      font-size: 14px;
//      line-height: 16px;
//      padding: 0 10px !important;
//      font-weight: 400 !important;
//      height: 30px;
//    }
//    .dx-row:nth-child(odd) td{
//      background-color: rgba(196, 196, 196, 0.5) !important;
//    }
//    .dx-row:nth-child(even) td{
//      background-color: #C4C4C4 !important;
//    }
//  }
//}
//
//.grid_luc_12{
//  .dx-datagrid .dx-row td, .dx-datagrid-text-content{
//    font-size: 12px !important;
//    line-height: 16px !important;
//  }
//}

//Important
.dx-datagrid-content {
  position: static;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons .dx-link,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link {
  color: $dataGridActionButtonColor;
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: $primaryColor;
}
//.dx-pager .dx-pages .dx-selection, .dx-pager .dx-page-sizes .dx-selection {
//  background-color: $pagingPerPageBackground;
//  color: $pagingPerPageColor;
//}

.dx-pager .dx-pages .dx-info {
  color: $pagingInfoColor;
  opacity: 1;
}
.mgb-sub-15 {
  margin-bottom: -15px !important;
}
.grid-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-color: $gridContainerBgColor;
  border: 1px solid $gridContainerBorderColor;
  border-right: none;
  border-left: none;
}

.grid-title {
  color: #2f78b9;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 25px;
  padding: 2px 0 0 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: $dataGridBgEvenColor;
  /*background-image: linear-gradient(rgb(190, 204, 216), rgb(202, 218, 222), rgb(190, 204, 216));*/
}

.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: 1px solid $gridContainerBorderColor;
}

.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none {
  border-left-width: 3px !important;
  border-right: 3px solid transparent !important;
}

//.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
//  background-color: #e6e6e6;
//  color: #333;
//}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
  padding-left: 4px;
}

.dx-checkbox-icon {
  border: 2px solid $black;
}

.paging {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  padding: 5px;
  min-width: 330px;
  font-weight: bold;
  background-color: $white;

  &.paging-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  .pageOf, .pagePer{
    margin-left:5px;
    margin-right:5px;
    text-align: center;
    height:25px;
    border-radius:5px;
  }
  .pagePer {
    background-color: #F5F5F5;
    &:hover, &:focus {
      background-color: $defaultColor;
    }
    select {
      padding-left: 9px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    svg {
      // top: calc(50% - 11px);
    }
    fieldset {
      border: none;
    }
  }
  .pageOf {
    width: 1%;
    margin-top: 6px;
    -moz-appearance: textfield;

    .MuiInput-underline:before {
      border-bottom-width: 2px;
    }

    input {
      text-align: center;
      padding: 3px 0 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  //.pageOf::-webkit-outer-spin-button,
  //.pageOf::-webkit-inner-spin-button {
  //  -webkit-appearance: none;
  //  margin: 0;
  //}
  .first span,
  .next span,
  .prev span,
  .last span
  {
    color: $fontColorDefault;
  }
  .first, .prev, .next, .last {
    padding: 6px 8px;
    border-radius: 50%;
    min-width: 18px;
  }
  .slash {
    margin: 0 3px;
    color: $fontColorDefault;
  }
}

.dx-invalid-message .dx-overlay-wrapper .dx-overlay-content {
  float: right;
  transform: translate(1px, -35px)!important;
}

.dx-popup-content:not(.dx-visibility-change-handler .dx-popup-content){
  padding: 0!important;
}

//update UI paging on tablet and mobile view.
@media only screen and (max-width: 960px) {
  .paging-crm{

    .txt-line-number{
      display: none;
    }
    .hide-mobile, .pagePer{
      display: none;
    }
  }
}
//.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper{
//  padding-bottom: 10px;
//}

.disabledSearch {
  display: none !important;
}

.grid-full-width{
  position: relative;
  .grid-container{
    overflow-x: scroll ;
    padding-top: 38px;
    .toolbar-container{
      position: absolute;
      left: 15px;
      width: calc(100% - 30px);
      top: 0;
    }
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content),
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) {
  padding-right: 16px;
  padding-left: 16px;

}

.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  color: #334BAB
}

.dx-datagrid-text-content {
  overflow: visible;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
  .display-action-button-w29f2005{
    visibility: hidden;
  }
}

.dx-datagrid-revert-tooltip .dx-revert-button {
  height: 37px;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td {
  //transform: scale(1);
  //box-shadow: 4px 2px 5px rgba(0, 0, 0, 0.16);
  //border-left: 0 !important;
  //border-right: 0 !important;
  background-color: $dataGridRowHoverColor;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td.grid-action-hover {
  box-shadow: none;
}

.dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-texteditor.dx-editor-filled .dx-placeholder:before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder:before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 8px 14px 8px;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
  height: 40px;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 7px 4px;
}

.dx-datagrid-filter-row .dx-menu {
  left: 0;
}

.dx-datagrid-focus-overlay:after {
  background-color: transparent;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.btn-grid-action {
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.dx-highlight-outline {
  padding: 0;
}

.grid-container-paging {
  margin-bottom: 37px;
}
.dx-overlay-wrapper.dx-invalid-message.dx-invalid-message-always.dx-datagrid-invalid-message{
  .dx-overlay-content.dx-resizable {
    top: 82px !important
  }
}

.dx-searchbox.dx-editor-filled .dx-placeholder:before,
.dx-searchbox.dx-editor-filled .dx-texteditor-input,
.dx-searchbox.dx-editor-outlined .dx-placeholder:before,
.dx-searchbox.dx-editor-outlined .dx-texteditor-input {
  padding-left: 34px !important;
}

.grid-container {
  .dx-state-disabled .dx-widget,
  .dx-state-disabled.dx-widget {
    opacity: .2;
  }
}

.dx-datagrid .dx-edit-row {
  height: 100% !important;
}

//.dx-scrollable-content {
//  position: static;
//}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td {
  background-color: $dataGridRowFocusColor;
  //color: #fff;
}

.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table  .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: transparent;
}

.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed) .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed).dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed).dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed) .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: $dataGridSelectedBgColor;
}

.dx-widget:not(.noBGSelect) .dx-datagrid-rowsview .dx-adaptive-detail-row:not(.dx-edit-row) .dx-cell-modified,
.dx-widget:not(.noBGSelect) .dx-datagrid-rowsview .dx-data-row:not(.dx-edit-row) .dx-cell-modified {
  background-color: $dataGridRowFocusColor;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-validator.dx-datagrid-invalid,
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-validator.dx-invalid,
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid,
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-invalid {
  background-color: #FFB3B1;
}

.dx-datagrid-rowsview .dx-master-detail-row .dx-master-detail-cell,
.dx-datagrid-rowsview .dx-master-detail-row>.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-row.dx-edit-row>td {
  border-top: none;
  border-bottom: none;
}

.dx-datebox-list .dx-dropdowneditor-icon:before {
  content: "\f01d";
}
.dx-datebox-calendar .dx-dropdowneditor-icon:before {
  content: "\f026";
}
.dx-datebox-native .dx-dropdowneditor-icon:before {
  content: none;
}
.dx-datebox-date.dx-datebox-native {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 24px;
    height: 24px;
    opacity: 0.45;
  }
}

.dx-datebox-calendar.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon:before,
.dx-datebox-calendar.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon:before {
  content: "\f026";
}
.dx-datebox-list.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon:before,
.dx-datebox-list.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon:before {
  content: "\f01d";
}

.dx-datagrid-headers.dx-header-multi-row .dx-row.dx-header-row > td:last-child {
  border-right: none;
}
