@mixin transition( $times: 0.5s ){
	transition: all $times ease;
	-webkit-transition: all $times ease;
}
.page-login.login-container{
	margin: 0;
	font-size: 14px;
	min-height: 100vh;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0;
	align-items: center;
	justify-content: center;
	position: relative;
	font-family: $fontDefault, sans-serif;
	background: url(../assets/images/login/background-login.svg) center no-repeat;
	background-size: cover;

	.MuiListItem-root.Mui-selected {
		font-weight: bold;
	}

	.bg-login{
		filter: blur(20px);
		-webkit-filter: blur(20px);
		width: 100%;
		height: 100%;
		object-fit: contain;
		position: absolute;
		top: 0;
		left: 0;
	}

	.error{
		text-align: center;
		min-height: 20px;
		margin-bottom: 20px;
		width: 100%;
	}

	.has-error .form-control {
		border-top: none !important;
		border-left: none !important;
		border-right: none !important;
		box-shadow: none !important;
	}

	.login-bound {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		box-shadow: 3px 0 50px #BBC1EC;
		height: auto;
		width: 100%;
		max-width: 944px;
		min-height: 502px;
		max-height: 600px;
		background: white;
		padding: 24px 64px;
		border-radius: 11px;
		margin: 15px;
		margin-top: -72px;
		@include transition();
		-webkit-animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		&:hover{
			@include transition();
			box-shadow: 0 3px 19px rgba(0,0,0,0.1);
		}
	}

	.login-symbol {
		width: 300px;
		height: 350px;
		font-size: 369px;
		line-height: 344px;
		background: #1ca4b5;
		//box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35);
		box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35);
		text-align: center;
		color:white
	}

	.login-label {
		font-size: inherit;
		line-height: 18px;
		margin-bottom: 0;
		color: #9C9C9C;
		font-weight: 200;
		font-family: $fontDefault, sans-serif ;
	}


	.panel-logo {
		width: 280px;
		height: 280px;
		padding: 48px;
		background-color: #FFFFFF;
		border-radius: 50%;
		overflow: hidden;
		box-shadow: 0 3px 89px #AFAFAF78;
	}

	.span-language, .span-power {
		font-size: 0.92rem;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		align-items: center;

		.MuiInput-root {
			font-size: 0.92rem;

			&:before, &:after {
				content: none;
			}
		}
	}

	.register_error{
		font-family: $fontDefault, sans-serif;
		font-weight: bold;
		font-size: 15px;
		position: absolute;
		top: 105px;
	}

	.login-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 0;
		color: $fontColorDefault;
		position: relative;
		i{
			color: $fontColorDefault;
			position: absolute;
			bottom: 17px;
			font-size: 14px;
			left: 2px;
		}

		.input-devextreme{
			padding-left: 0;
		}
		input[type='radio']{
			transition: all 0.5s;
			margin: 0 10px 0 0 ;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			display: inline-block;
			width: 23px;
			height: 23px;
			padding: 2px;
			background-clip: content-box;
			border: 3px solid #D4D4D4;
			background-color: transparent;
			border-radius: 50%;
			&:checked {
				background-color: #85D62F;
			}
		}
	}

	.login-input {
		border-top: none;
		border-left: none;
		border-right: none;
		box-shadow: none;
		border-radius: 0;
		-webkit-box-shadow: none;
	}

	.login-bt {
		outline: none !important;
		background-color: $primaryColor !important;
		border-color: #3b6e22 #3b6e22 #2c5115;
		color: #fff !important;
		cursor: pointer;
		letter-spacing: 1px;
		position: relative;
		width: 100%;
		max-width: 245px;
		text-shadow: 0 1px 2px rgba(0,0,0,.5);
		border: 0 !important;
		border-radius: 8px;
		height: auto;
		min-height: 46px;
		font-family: $fontDefault, sans-serif;
		font-weight: bold;
		font-size: 1.143rem !important;
		text-align: center;
		transition: 0.25s;
		box-shadow: 0 7px 10px 2px #ddd;

	}

	.login-bt:hover {
		color: white !important;
		outline: none !important;
		border: none !important;
		background-image: none;
		opacity: 0.9;
		filter: brightness(125%);
	}

	.login-bt:active,

	.login-bt:focus,

	.login-bt:active,

	.login-bt:disabled {
		color: white !important;
		outline: none !important;
		border: none !important;
		background-image: none;
		filter: brightness(125%);
		animation: shadow-pulse 1s;
	}

	.login-input:active,

	.login-input:focus,

	.login-input.active {
		background-image: none;
		outline: none !important;
		box-shadow: none !important;
	}

	.logo{
		min-width: 64px;
		max-width: 100%;
		max-height: 100%;
		height: auto;
		object-fit: contain;
	}

	.header{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 64px;
	}

	.dx-textbox{
		border: 0 !important;
		position: relative;
		overflow: hidden;
		border-radius: 0;

		.dx-texteditor-container{
			margin-bottom: 2px;
		}

		&.dx-state-focused, &:hover{
			&:before, &:after{
				@include transition(0.5s);
			}
			&:before{
				left: 0;
			}
			&:after{
				left: 100%;
			}
		}
		//&.input-pass.dx-textbox.dx-state-focused, &.input-pass.dx-textbox:hover{
		//	&:after{
		//		background: #6dc0fb;
		//	}
		//}
		//&.input-pass.dx-textbox.dx-state-focused,
		//&.input-pass.dx-textbox:hover,
		//&.input-user.dx-textbox.dx-state-focused,
		//&.input-user.dx-textbox:hover{
		//	&:after{
		//		background: #6dc0fb;
		//	}
		//}

		&:before, &:after{
			content: '';
			display: inline-block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			transform: none;
			@include transition();
			background: rgba(158,193,214, 0.22);
		}
		&:before{
			background: #3c3c3c;
			left: -100%;
		}
		&:after{
			left: 0;
			background: $defaultColor;
		}
	}

	.input-pass.dx-textbox, .input-name.dx-textbox{
		&:after{
			background: $defaultColor;
		}
	}

	.dx-texteditor-input, .dx-placeholder{
		font-size: 1.1rem;
		line-height: 18px;
		padding: 12px 0 8px 0;
		font-weight: 400;
		font-family: $fontDefault, sans-serif;
		letter-spacing: 0.7px;
	}

	.dx-placeholder{
		color: #D3D3D3!important;
		padding: 0;
		&:after, &:before{
			padding: 0 0;
		}
	}

	.support-view{
		font-size: 20px;
		margin: 10px 0 0 0;
		img{
			width: 50px;
			height: 50px;
			margin: 0 18px;
		}
		b{
			font-family: $fontDefault, sans-serif;
			font-weight: bold;
			color: #000000;
		}
	}

	.view-login{
		display: flex;
		flex-direction: row;
		.login-bt{
			margin: 70px 0;
		}
		label{
			font-size: 16px;
			line-height: 18px;
			color:#59617B;
			margin:0
		}
	}

	.login-wrapper-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 72px;
		padding: 20px 45px;
		background-color: white;
		margin: 0;
	}

	.login-footer{
		display: flex;
		width: 100%;
		max-width: 1024px;
		margin: 0 15px;

		div{
			padding: 0;
		}
		div:first-child{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			span {
				font-size: 1.1rem;
				font-weight: 100;
			}

			label {
				font-weight: bold;
				color: #696B73;
			}
		}
		div:last-child{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			font-size: 15px;
			line-height: 20px;
			font-family: $fontDefault, sans-serif;
			color: #646464;

			img{
				width: 100px;
				object-fit: cover;
			}
		}
	}

	@media ( max-width: 680px  ){
		font-size: 12px;
		.header {
			justify-content: center;
			span {
				display: none;
			}
		}
		.login-bound {
			margin-top: 15px;
		}
		.panel-logo {
			display: none;
		}
		.login-wrapper-footer {
			height: auto;
			padding: 15px;

			.login-footer div:first-child {
				align-items: center;
			}
		}
	}

	@media ( max-width: 480px  ){
		padding-bottom: 110px;
		.header {
			img {
				width: 60%;
			}
		}
		.login-bound {
			padding: 20px;
		}
		.login-bt{
			margin-top: 40px;
		}
		.support-view{
			img {
				width: 30px;
				height: 30px;
			}
			font-size: 14px;
		}
		.register_error{
			top: auto;
			bottom: 140px;
			font-size: 12px;
		}
		.login-footer{
			flex-direction: column;
			div{
				align-items: center;
				justify-content: center !important;
			}
			div:first-child{
				border-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}

@keyframes shadow-pulse
{
	0% {
		box-shadow: 0 0 0 0 $primaryColor;
	}
	100% {
		box-shadow: 0 0 0 28px rgba(0, 0, 0, 0);
	}
}

@-webkit-keyframes slide-bottom {
	0% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes slide-bottom {
	0% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}
